import React from 'react';
import { isExternalUrl } from '../../../../utils/context/utils';
import LazyloadImage from '../../../sharedComponents/LazyloadImage';

export type CardProps = {
    cardHeader: string;
    image: {
        altText?: string;
        imageUrl: string;
    };
    url: string;
};

export function InterlinkingCard({
    cardHeader,
    image,
    url,
    children,
}: React.PropsWithChildren<CardProps>) {
    const isExternal = isExternalUrl(url);

    return (
        <li>
            <a
                href={url}
                {...(isExternal && {
                    target: '_blank',
                    rel: 'nofollow noopener noreferrer',
                })}
            >
                <LazyloadImage
                    alt={image.altText ?? ''}
                    image={image.imageUrl}
                />
                <span>{cardHeader}</span>
                {!!children && <div>{children}</div>}
            </a>
        </li>
    );
}
