import React from 'react';
import {
    InterlinkingCards,
    InterlinkingCardsProps,
} from './components/InterlinkingCards';

export default function InterlinkingCardsModule({
    eyebrow,
    header,
    interlinkingCards,
}: InterlinkingCardsProps) {
    return (
        <section>
            <div className="interlinking-cards-module-container module-landing-page__module-container-content">
                <InterlinkingCards
                    eyebrow={eyebrow}
                    header={header}
                    interlinkingCards={interlinkingCards}
                />
            </div>
        </section>
    );
}
