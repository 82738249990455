import { ESizes, useMediaQuery } from '@eventbrite/wagtail-components';
import React from 'react';
import { CardProps, InterlinkingCard } from './InterlinkingCard';
import './interlinkingCards.scss';

export type InterlinkingCardsProps = {
    eyebrow?: string;
    header?: string;
    interlinkingCards: CardProps[];
    useH1?: boolean;
};

export function InterlinkingCards({
    eyebrow,
    header,
    interlinkingCards,
    useH1 = false,
}: InterlinkingCardsProps) {
    const activeSize = useMediaQuery();
    let moreThanCardLimit;
    switch (activeSize) {
        case ESizes.small: {
            moreThanCardLimit = interlinkingCards.length > 4;
            break;
        }
        case ESizes.medium: {
            moreThanCardLimit = interlinkingCards.length > 6;
            break;
        }
        case ESizes.large: {
            moreThanCardLimit = interlinkingCards.length > 6;
            break;
        }
        default: {
            moreThanCardLimit = interlinkingCards.length > 9;
        }
    }
    return (
        <div className="interlinking-cards">
            {!!eyebrow && (
                <span className="interlinking-cards__eyebrow">
                    {/* .toUpper() to enforce uniform small-caps */}
                    {eyebrow.toUpperCase()}
                </span>
            )}
            {!!header && (
                <>
                    {useH1 ? (
                        <h1 className="interlinking-cards__header">{header}</h1>
                    ) : (
                        <h2 className="interlinking-cards__header">{header}</h2>
                    )}
                </>
            )}

            {/*
                do not render the below element on the page.
                this checkbox exists to manage state without JS.
                more info: https://css-tricks.com/the-checkbox-hack/
            */}
            {moreThanCardLimit && (
                <input
                    id="interlinking-cards__toggle"
                    type="checkbox"
                    aria-hidden="true"
                    tabIndex={-1}
                />
            )}
            <ul className="interlinking-cards__card-container">
                {/*
                    the hidden elements are shown through the checkbox toggle.
                    see: "./interlinkingCards.scss", line 61
                */}
                {interlinkingCards.map((card, i) => (
                    <InterlinkingCard
                        image={card.image}
                        cardHeader={card.cardHeader}
                        url={card.url}
                        key={i}
                    />
                ))}
            </ul>
            {/* this is the "button" to toggle hidden elements */}
            {moreThanCardLimit && (
                <label
                    role="button"
                    htmlFor="interlinking-cards__toggle"
                    tabIndex={0}
                    aria-label="Click to show more"
                    className="interlinking-cards__cta"
                    data-type="show"
                >
                    {/* these are also toggled with the checkbox. */}
                    <span data-type="show">Show more</span>
                    <span data-type="hide">Show less</span>
                </label>
            )}
        </div>
    );
}
